/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import isIE from 'Services/isIE';
import Loadable from 'Components/Loadable';

// RootComponent
const App = Loadable(() => import('./app.js'));
const NOIE = Loadable(() => import('./NoIE'));

const root = createRoot(document.getElementById('root'));

if (isIE()) {
  root.render(<NOIE />);
} else {
  root.render(<App />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
